import { MoreHoriz, PlayArrow, Stop } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { MouseEvent, useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';

import { PredictiveIvrMessage } from '../../domain';

interface MessageCardProps {
  ivrMessage: PredictiveIvrMessage;
  isPlaying: boolean;
  onPlayAudio: () => void;
  onStopAudio: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const MessageCard = ({ ivrMessage, isPlaying, onPlayAudio, onStopAudio, onEdit, onDelete }: MessageCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false);

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const triggerEdit = () => {
    setAnchorEl(null);
    onEdit();
  };

  const triggerDelete = () => {
    onDelete();
    setDeleteConfirmModalOpen(false);
  };

  const handleDeleteConfirmationOpen = () => {
    setAnchorEl(null);
    setDeleteConfirmModalOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmModalOpen(false);
  };

  return (
    <>
      <OberonCard
        titleFontWeight={700}
        title={ivrMessage.key}
        subHeader={ivrMessage.description}
        action={
          <>
            {ivrMessage.signedUrl && (
              <>
                <span>{ivrMessage.filename ? 'Audio File' : 'SSML'}</span>
                {!isPlaying ? (
                  <IconButton aria-haspopup='false' onClick={onPlayAudio}>
                    <PlayArrow sx={{ height: 38, width: 38 }} />
                  </IconButton>
                ) : (
                  <IconButton aria-haspopup='false' onClick={onStopAudio}>
                    <Stop sx={{ height: 38, width: 38 }} />
                  </IconButton>
                )}
              </>
            )}

            <IconButton aria-controls='agent-menu' aria-haspopup='true' onClick={handleMenuOpen}>
              <MoreHoriz />
            </IconButton>

            <Menu id='agent-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={triggerEdit}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteConfirmationOpen}>Delete</MenuItem>
            </Menu>
          </>
        }
      />

      <ActionDialog
        open={deleteConfirmModalOpen}
        title='Are you sure you want to do this?'
        content={`You are about to delete predictive IVR message ${ivrMessage.key}.`}
        onClose={handleDeleteConfirmationClose}
        onAccept={triggerDelete}
        primaryActionTitle='Delete'
      />
    </>
  );
};

export default MessageCard;
