import 'core-js/stable';
import 'regenerator-runtime';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import axios, { AxiosError } from 'axios';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { LogRocketProvider } from '~providers/LogRocketProvider';
import PageTitleProvider from '~providers/PageTitleProvider';
import UserPreferencesProvider from '~providers/UserPreferencesProvider';

import BrowserSupport from './components/BrowserSupport';
import Pages from './pages';
import AppConfigurationProvider from './providers/AppConfigurationProvider';
import AuthProvider from './providers/AuthProvider';
import NotificationProvider from './providers/NotificationProvider';
import RouteProvider from './providers/RouteProvider';
import theme from './theme';

// Adds global auth based response intercepting for axios
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error: AxiosError) => {
    const status = error.response?.status ?? undefined;

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    switch (status) {
      case 401:
      case 403: {
        window.location.reload();
        return Promise.reject(error);
      }
      default: {
        return Promise.reject(error);
      }
    }
  },
);

const root = createRoot(document.getElementById('app')!);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserSupport>
        <AuthProvider>
          <AppConfigurationProvider>
            <LogRocketProvider>
              {/* Used by mui to change the date-engine locale to use luxon  */}
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <NotificationProvider>
                  <UserPreferencesProvider>
                    <BrowserRouter>
                      <RouteProvider>
                        <PageTitleProvider>
                          <Pages />
                        </PageTitleProvider>
                      </RouteProvider>
                    </BrowserRouter>
                  </UserPreferencesProvider>
                </NotificationProvider>
              </LocalizationProvider>
            </LogRocketProvider>
          </AppConfigurationProvider>
        </AuthProvider>
      </BrowserSupport>
    </ThemeProvider>
  </StrictMode>,
);
