export const PolicyType = {
  Agent: 'agent',
  TeamLeader: 'team-leader',
  Manager: 'manager',
  RealTimeAnalyst: 'real-time-analyst',
  QualityAnalyst: 'quality-analyst',
  WorkforceAnalyst: 'workforce-analyst',
  DiallerAdmin: 'dialler-admin',
} as const;

export const AccessScope = {
  CanDownloadCallbackFile: 'can-download-callback-file',
  CanSetAccessFilter: 'can-set-access-filter',
  CanCreateLeadsList: 'can-create-leads-list',
  CanUpdateLeadsListGeneralSettings: 'can-update-leads-list',
  CanMoveLeadsList: 'can-move-leads-list',
  CanViewLeadListSettings: 'can-view-lead-list-settings',
  CanAssignAgentToGroup: 'can-assign-agent-to-group',
  CanRemoveAgentFromGroup: 'can-remove-agent-from-group',
  CanAssignCampaignToGroup: 'can-assign-campaign-to-group',
  CanArchiveDiallerGroup: 'can-archive-dialler-group',
  CanUpdateDiallerGroupCampaign: 'can-update-dialler-group-campaign',
  CanRemoveDiallerGroupCampaign: 'can-remove-dialler-group-campaign',
  CanViewLeads: 'can-view-leads',
  CanUploadLeads: 'can-upload-leads',
  CanRemoveLeads: 'can-remove-leads',
  CanCreateGroup: 'can-create-group',
  CanViewGroupAgents: 'can-view-group-agents',
  CanCreateCampaign: 'can-create-campaign',
  CanArchiveCampaign: 'can-archive-campaign',
  CanViewCampaignLists: 'can-view-campaign-lists',
  CanViewCampaignDispositions: 'can-view-campaign-dispositions',
  CanViewCampaignFilters: 'can-view-campaign-filters',
  CanViewCampaignEndpointTypeSettings: 'can-view-campaign-endpoint-type-settings',
  CanViewCampaignSettings: 'can-view-campaign-settings',
  CanUpdateCampaignGeneralSettings: 'can-update-campaign-general-settings',
  CanUpdateCampaignPreviewSettings: 'can-update-campaign-preview-settings',
  CanUpdateCampaignPredictiveSettings: 'can-update-campaign-predictive-settings',
  CanUpdateCampaignLeadEngineSettings: 'can-update-campaign-lead-engine-settings',
  CanUpdateCampaignSMSSettings: 'can-update-campaign-sms-settings',
  CanUpdateCampaignTrunksSettings: 'can-update-campaign-trunks-settings',
  CanViewDiallerGroupSettings: 'can-view-dialler-group-settings',
  CanUpdateDiallerGroupGeneralSettings: 'can-update-dialler-group-general-settings',
  CanUpdateDiallerGroupPreviewSettings: 'can-update-dialler-group-preview-settings',
  CanUpdateDiallerGroupPredictiveSettings: 'can-update-dialler-group-predictive-settings',
  CanViewCallRecordings: 'can-view-call-recordings',
  CanDownloadCallRecordings: 'can-download-call-recordings',
  CanViewChatTranscripts: 'can-view-chat-transcripts',
  CanViewScreenRecordings: 'can-view-screen-recordings',
  CanDownloadScreenRecordings: 'can-download-screen-recordings',
  CanViewContactAssessment: 'can-view-contact-assessment',
  CanViewCallMetrics: 'can-view-call-metrics',
  CanDownloadContactLogs: 'can-download-contact-logs',
  CanViewTransfers: 'can-view-transfers',
  CanViewAccessFilters: 'can-view-access-filters',
  CanViewAccessFilterSettings: 'can-view-access-filter-settings',
  CanViewDiallerControls: 'can-view-dialler-controls',
  CanUpdateDiallerControlsGlobalSettings: 'can-update-dialler-controls-global-settings',
} as const;

export type PolicyTypeValues = typeof PolicyType[keyof typeof PolicyType];
export type AccessScopeValues = typeof AccessScope[keyof typeof AccessScope];

export const accessScopesByPolicy: {
  readonly [key in PolicyTypeValues]: readonly AccessScopeValues[];
} = {
  [PolicyType.Agent]: [],
  [PolicyType.TeamLeader]: [
    AccessScope.CanSetAccessFilter,
    AccessScope.CanViewLeads,
    AccessScope.CanAssignAgentToGroup,
    AccessScope.CanRemoveAgentFromGroup,
    AccessScope.CanViewCampaignLists,
    AccessScope.CanViewCallRecordings,
    AccessScope.CanViewChatTranscripts,
    AccessScope.CanViewScreenRecordings,
    AccessScope.CanViewContactAssessment,
    AccessScope.CanViewCallMetrics,
    AccessScope.CanViewGroupAgents,
  ],
  [PolicyType.Manager]: [
    AccessScope.CanDownloadCallbackFile,
    AccessScope.CanSetAccessFilter,
    AccessScope.CanViewLeads,
    AccessScope.CanAssignAgentToGroup,
    AccessScope.CanRemoveAgentFromGroup,
    AccessScope.CanViewCampaignLists,
    AccessScope.CanViewCallRecordings,
    AccessScope.CanDownloadCallRecordings,
    AccessScope.CanViewChatTranscripts,
    AccessScope.CanViewScreenRecordings,
    AccessScope.CanDownloadScreenRecordings,
    AccessScope.CanViewContactAssessment,
    AccessScope.CanViewCallMetrics,
    AccessScope.CanDownloadContactLogs,
    AccessScope.CanViewGroupAgents,
  ],
  [PolicyType.RealTimeAnalyst]: [
    AccessScope.CanSetAccessFilter,
    AccessScope.CanViewCampaignLists,
    AccessScope.CanViewDiallerGroupSettings,
    AccessScope.CanUpdateDiallerGroupPreviewSettings,
    AccessScope.CanUpdateDiallerGroupPredictiveSettings,
    AccessScope.CanUpdateDiallerGroupCampaign,
    AccessScope.CanViewCampaignSettings,
    AccessScope.CanViewLeadListSettings,
    AccessScope.CanUpdateLeadsListGeneralSettings,
    AccessScope.CanAssignAgentToGroup,
    AccessScope.CanRemoveAgentFromGroup,
  ],
  [PolicyType.QualityAnalyst]: [
    AccessScope.CanDownloadCallbackFile,
    AccessScope.CanSetAccessFilter,
    AccessScope.CanViewCallRecordings,
    AccessScope.CanDownloadCallRecordings,
    AccessScope.CanViewChatTranscripts,
    AccessScope.CanViewScreenRecordings,
    AccessScope.CanDownloadScreenRecordings,
    AccessScope.CanViewContactAssessment,
    AccessScope.CanViewCallMetrics,
    AccessScope.CanDownloadContactLogs,
  ],
  [PolicyType.WorkforceAnalyst]: [AccessScope.CanSetAccessFilter, AccessScope.CanViewGroupAgents],
  [PolicyType.DiallerAdmin]: [
    AccessScope.CanDownloadCallbackFile,
    AccessScope.CanSetAccessFilter,
    AccessScope.CanCreateLeadsList,
    AccessScope.CanUpdateLeadsListGeneralSettings,
    AccessScope.CanMoveLeadsList,
    AccessScope.CanViewLeadListSettings,
    AccessScope.CanViewLeads,
    AccessScope.CanAssignAgentToGroup,
    AccessScope.CanRemoveAgentFromGroup,
    AccessScope.CanAssignCampaignToGroup,
    AccessScope.CanUploadLeads,
    AccessScope.CanRemoveLeads,
    AccessScope.CanCreateCampaign,
    AccessScope.CanCreateGroup,
    AccessScope.CanViewGroupAgents,
    AccessScope.CanViewCampaignLists,
    AccessScope.CanViewCampaignDispositions,
    AccessScope.CanViewCampaignFilters,
    AccessScope.CanViewCampaignEndpointTypeSettings,
    AccessScope.CanViewCampaignSettings,
    AccessScope.CanUpdateCampaignGeneralSettings,
    AccessScope.CanUpdateCampaignPreviewSettings,
    AccessScope.CanUpdateCampaignPredictiveSettings,
    AccessScope.CanUpdateCampaignLeadEngineSettings,
    AccessScope.CanUpdateCampaignSMSSettings,
    AccessScope.CanUpdateCampaignTrunksSettings,
    AccessScope.CanArchiveCampaign,
    AccessScope.CanArchiveDiallerGroup,
    AccessScope.CanUpdateDiallerGroupCampaign,
    AccessScope.CanRemoveDiallerGroupCampaign,
    AccessScope.CanViewDiallerGroupSettings,
    AccessScope.CanUpdateDiallerGroupGeneralSettings,
    AccessScope.CanUpdateDiallerGroupPreviewSettings,
    AccessScope.CanUpdateDiallerGroupPredictiveSettings,
    AccessScope.CanViewCallRecordings,
    AccessScope.CanDownloadCallRecordings,
    AccessScope.CanViewChatTranscripts,
    AccessScope.CanViewScreenRecordings,
    AccessScope.CanDownloadScreenRecordings,
    AccessScope.CanViewContactAssessment,
    AccessScope.CanViewCallMetrics,
    AccessScope.CanDownloadContactLogs,
    AccessScope.CanViewTransfers,
    AccessScope.CanViewAccessFilters,
    AccessScope.CanViewAccessFilterSettings,
    AccessScope.CanViewDiallerControls,
    AccessScope.CanUpdateDiallerControlsGlobalSettings,
  ],
} as const;
