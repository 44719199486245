import axios from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  AgentUpdateRequest,
  CreateExclusionList,
  DispositionAttribute,
  ExclusionEntry,
  ExclusionList,
  ExclusionListListedEndpoint,
  ExclusionListUploadResponse,
  GetDispositionAttributesResponseDecoder,
  GetExclusionListListedEndpointResponseDecoder,
  GetExclusionListUploadResponseDecoder,
  GetExclusionListsResponseDecoder,
  GetPredictiveAgentStatusesDecoder,
  GetPredictiveIvrMessagesResponseDecoder,
  GetRecordingInfoListResponseDecoder,
  GetSipQueuesResponseDecoder,
  GetSkillTypesResponseDecoder,
  NewSipQueue,
  PredictiveAgentStatus,
  PredictiveIvrMessage,
  RecordingInfo,
  SipQueue,
  SkillType,
} from './domain';

export const createAgent = async (data: AgentUpdateRequest): Promise<void> => {
  const path = '/api/agents/';

  const body = {
    first_name: data.firstName,
    last_name: data.lastName,
    username: data.username,
    dialler_group_id: data.diallerGroupId,
    routing_profile_id: data.routingProfileId,
    skills: data.skills,
    async_queues: data.asyncQueues,
    async_max_concurrency: data.asyncMaxConcurrency,
    async_desired_concurrency: data.asyncDesiredConcurrency,
    access_filter_ids: data.accessFilterIds,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateAgentByUsername = async (data: AgentUpdateRequest): Promise<void> => {
  const path = `/api/agents/${data.username}`;

  const body = {
    first_name: data.firstName,
    last_name: data.lastName,
    dialler_group_id: data.diallerGroupId,
    routing_profile_id: data.routingProfileId,
    skills: data.skills,
    async_queues: data.asyncQueues,
    async_max_concurrency: data.asyncMaxConcurrency,
    async_desired_concurrency: data.asyncDesiredConcurrency,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deleteAgentByUsername = async (username: string): Promise<void> => {
  const path = `/api/agents/${username}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getSkillTypes = async (): Promise<SkillType[]> => {
  const path = '/api/skills/';
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetSkillTypesResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createSkillType = async (data: SkillType): Promise<void> => {
  const path = '/api/skills/';

  const body = {
    skill_type: data.skillType,
    description: data.description,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getExclusionLists = async (): Promise<ExclusionList[]> => {
  const path = '/api/exclusion-list/';
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetExclusionListsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createExclusionList = async (data: CreateExclusionList): Promise<void> => {
  const path = '/api/exclusion-list/';

  const body = {
    name: data.name,
    description: data.description,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const addEndpointToExclusionList = async (listName: string, data: ExclusionEntry): Promise<void> => {
  const path = `/api/exclusion-list/${listName}`;

  const body = {
    number: data.number,
    reason: data.reason,
    expiry_timestamp: data.expiryTimestamp,
    timezone: data.timezone,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const bulkAddEndpointsToExclusionList = async (
  listName: string,
  file: File,
  onUploadProgress: (progress: number) => void,
  expiryTimestamp?: string,
): Promise<ExclusionListUploadResponse> => {
  const path = `/api/exclusion-list/${listName}/upload`;
  let resp;

  const formData = new FormData();
  formData.append('file', file);
  if (expiryTimestamp) {
    formData.append('expiry_timestamp', expiryTimestamp);
  }

  try {
    resp = await axios.request({
      method: 'POST',
      url: path,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.total ?? 0;
        const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
        onUploadProgress(percentCompleted);
      },
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetExclusionListUploadResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const checkEndpointExistsInExclusionList = async (
  listName: string,
  endpoint: string,
): Promise<ExclusionListListedEndpoint> => {
  const path = `/api/exclusion-list/${listName}/phone/${endpoint}`;
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetExclusionListListedEndpointResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const removeEndpointFromExclusionList = async (listName: string, endpoint: string): Promise<void> => {
  const path = `/api/exclusion-list/${listName}/phone/${endpoint}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getAgentRecordingInfo = async (agentUsername: string): Promise<RecordingInfo[]> => {
  const path = `/api/screen-recordings/agent/${agentUsername}`;
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetRecordingInfoListResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getDispositionAttributes = async (): Promise<DispositionAttribute[]> => {
  const path = '/api/disposition-attributes/';
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetDispositionAttributesResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getPredictiveIvrMessages = async (): Promise<PredictiveIvrMessage[]> => {
  const path = '/api/predictive-ivr-messages';
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetPredictiveIvrMessagesResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const upsertPredictiveIvrMessage = async (isUpdate: boolean, msg: PredictiveIvrMessage): Promise<void> => {
  const method = isUpdate ? 'PUT' : 'POST';
  const path = isUpdate ? `/api/predictive-ivr-messages/${msg.key}` : `/api/predictive-ivr-messages/`;

  let body: any = msg;
  if (msg.file) {
    body = new FormData();
    body.append('file', msg.file);
    body.append('message', JSON.stringify({ ...msg, file: undefined }));
  }

  try {
    await axios.request({
      method: method,
      url: path,
      withCredentials: true,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deletePredictiveIvrMessage = async (key: string): Promise<void> => {
  const path = `/api/predictive-ivr-messages/${key}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getS3AudioFileFromUrl = async (url: string): Promise<Blob> => {
  try {
    const response = await axios.request({
      method: 'GET',
      url: url,
      responseType: 'blob',
      headers: {
        'Cache-control': 'public, max-age=900',
      },
    });

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw new APIError(e.response!.status, e.message);
    }
    throw new APIError(-1, e as string);
  }
};

export const upsertDispositionAttribute = async (isUpdate: boolean, data: DispositionAttribute): Promise<void> => {
  const path = '/api/disposition-attributes/';
  const method = isUpdate ? 'PUT' : 'POST';

  const body = {
    attribute: data.attribute,
    values: data.values,
  };

  try {
    await axios.request({
      method: method,
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deleteDispositionAttribute = async (attribute: string): Promise<void> => {
  const path = `/api/disposition-attributes/${attribute}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getPredictiveAgentStatuses = async (): Promise<PredictiveAgentStatus[]> => {
  const path = `/api/predictive-agent-statuses`;
  let resp;
  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetPredictiveAgentStatusesDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const putPredictiveAgentStatuses = async (statuses: PredictiveAgentStatus[]): Promise<void> => {
  const path = `/api/predictive-agent-statuses`;
  const body = {
    statuses: statuses.map((status) => ({
      agent_status: status.agentStatus,
      position: status.position,
      description: status.description,
    })),
  };
  try {
    await axios.request({
      method: 'PUT',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      data: body,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postPredictiveAgentStatus = async (status: PredictiveAgentStatus): Promise<void> => {
  const path = `/api/predictive-agent-statuses`;
  const data = {
    agent_status: status.agentStatus,
    description: status.description,
    position: status.position,
  };

  try {
    await axios.request({
      data,
      method: 'POST',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deletePredictiveAgentStatus = async (status: string): Promise<void> => {
  const path = `/api/predictive-agent-statuses/${status}`;
  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deleteSipQueue = async (queueID: number): Promise<void> => {
  const path = `/api/predictive-contact-flows/queues/${queueID}/`;
  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw new APIError(e.response!.status, e.message);
    }
    throw new APIError(-1, e as string);
  }
};

export const updateSipQueue = async (queue: SipQueue): Promise<void> => {
  const path = `/api/predictive-contact-flows/queues/${queue.queueID}/`;
  const data = {
    queue_name: queue.queueName,
    description: queue.description,
  };
  try {
    await axios.request({
      data,
      method: 'PUT',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw new APIError(e.response!.status, e.message);
    }
    throw new APIError(-1, e as string);
  }
};

export const createSipQueue = async (queue: NewSipQueue): Promise<void> => {
  const path = `/api/predictive-contact-flows/queues/`;
  const data = {
    queue_name: queue.queueName,
    description: queue.description,
  };
  try {
    await axios.request({
      data,
      method: 'POST',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw new APIError(e.response!.status, e.message);
    }
    throw new APIError(-1, e as string);
  }
};

export const getSipQueues = async (search: string): Promise<SipQueue[]> => {
  const path = `/api/predictive-contact-flows/queues/`;
  let resp;
  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      params: {
        search,
      },
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw new APIError(e.response!.status, e.message);
    }
    throw new APIError(-1, e as string);
  }

  const decoded = GetSipQueuesResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
